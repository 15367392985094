import axios from 'axios';

export const fetchHashrateData = async () => {
  try {
    const response = await axios.get('https://api.taritracker.com/local/stratum'); // Replace 'API_URL' with your actual API URL
    const hashrate = response.data.hashrate_15m; // Extract the hashrate_15m field
    const convertedHashrate = (hashrate * Math.pow(10, -3)).toFixed(2); // Convert and format to 2 decimal places
    return parseFloat(convertedHashrate); // Convert back to a number (optional, depending on your use case)
  } catch (error) {
    console.error('Error fetching hashrate data:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};
