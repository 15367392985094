import React, { useState, useEffect } from 'react';
import './App.css';
import { fetchHashrateData } from './api/moneroStats';

const App = () => {
  const [hashrate15m, setHashrate15m] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getHashrate = async () => {
      try {
        const hashrate = await fetchHashrateData(); // Fetch the hashrate
        setHashrate15m(hashrate); // Update state with the fetched data
      } catch (err) {
        setError(err.message); // Handle errors
      }
    };

    getHashrate(); // Call the function
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div className="App">
      {/* Hero Section */}
      <section className="Hero">
        <div className="Hero-content">
          <h1 className="Hero-title">Tari Tracker</h1>
          <p className="Hero-slogan">A next generation application for a next generation blockchain.</p>
          
          {error ? (
            <p style={{ color: 'red' }}>Error: {error}</p>
          ) : hashrate15m !== null ? (
            <p className="Hero-coming-soon">Our Current Monero Pool Hashrate: {hashrate15m} kH/s</p>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </section>
      {/* Call to Action Section */}
      <section className="CTA">
        <h2 className="CTA-title">Ready to Build the Future?</h2>
        <a href="https://github.com/kstrycharz/tariblocktools" target="_blank" rel="noopener noreferrer">
          <button className="download-button">Try the Beta</button>
        </a>
      </section>


      {/* Features Section */}
      <section className="Features">
        <div className="Feature-cards">
          <div className="Feature-card">
            <h3>Built on Tari's L2 Blockchain</h3>
            <p>Leverage Tari's secure, scalable, and privacy-focused blockchain infrastructure.</p>
          </div>
          <div className="Feature-card">
            <h3>Advanced Tracking and Insights</h3>
            <p>Monitor and analyze application performance with precision tools designed for developers.</p>
          </div>
          <div className="Feature-card">
            <h3>Easy Integration</h3>
            <p>Integrate seamlessly with your existing solutions or start fresh with a fully customizable toolkit.</p>
          </div>
        </div>
      </section>

      
      {/* Footer */}
      <footer className="Footer">
        <p>&copy; 2024 Tari Tracker. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;
